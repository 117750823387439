import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";

import Layout from "../components/layout/layout";
import * as styles from "./food-menu.module.scss";

//* Remember use graphql tool http://localhost:8000/___graphql */
const FoodMenu = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulFoodMenuItem(
                sort: { fields: publishedDate, order: DESC }
            ) {
                edges {
                    node {
                        title
                        slug
                        image {
                            file {
                                url
                            }
                        }
                        publishedDate(formatString: "D MMM, YYYY")
                    }
                }
            }
        }
    `);

    return (
        <Layout title="DL's Menu">
            <div>
                <ol className={styles.posts}>
                    {data.allContentfulFoodMenuItem.edges.map(edge => {
                        return (
                            <li className={styles.post}>
                                <Link
                                    to={`/food-menu/${edge.node.slug
                                        .toLowerCase()
                                        .replace(/\s+/g, "")}`}
                                >
                                    <h2>{edge.node.title}</h2>
                                    {/* <p>{edge.node.publishedDate}</p> */}

                                    <img
                                        className={styles.foodMenuImage}
                                        src={edge.node.image.file.url}
                                        width="580"
                                        height="400"
                                        alt="Ultimate Aqua"
                                    />
                                </Link>
                            </li>
                        );
                    })}
                </ol>
            </div>
        </Layout>
    );
};

export default FoodMenu;
